const labelsSort = (a, b) => {
    const nameA = a.trim().toLowerCase();
    const nameB = b.trim().toLowerCase();

    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;

    return 0;
}

export default (data = []) => {
    const labels = [];
    const consentData = {};

    data.forEach(elem => {
        if (!elem?.consent_name) return;

        if (!consentData[elem?.consent_name]) {
            consentData[elem?.consent_name] = {
                total_values: 0,
                granted: 0,
                denied: 0,
            }
        }

        consentData[elem?.consent_name].total_values += elem?.total_values;
        consentData[elem?.consent_name].granted += elem?.granted;
        consentData[elem?.consent_name].denied += elem?.denied;
    });

    const grantedArr = [], deniedArr = [];

    Object.keys(consentData).sort(labelsSort).forEach((key) => {
        const grantedPercent = Math.round(consentData[key]?.granted / (consentData[key]?.total_values || 1) * 100, 0);
        const deniedPercent = Math.round(consentData[key]?.denied / (consentData[key]?.total_values || 1) * 100, 0);

        labels.push(key);
        grantedArr.push(grantedPercent);
        deniedArr.push(deniedPercent);
    });

    const datasets = [
        {
            label: "Granted",
            data: grantedArr,
            backgroundColor: "rgb(43, 43, 181)",
            barThickness: 25,
        },
        {
            label: "Denied",
            data: deniedArr,
            backgroundColor: "rgb(255, 189, 136)",
            barThickness: 25,
        },
    ];

    return {
        labels: labels,
        datasets: datasets,
    };
}
