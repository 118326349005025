import { React, useState, useEffect } from 'react';
import { X } from 'react-bootstrap-icons';
import { Modal, Popover, Flex } from 'antd';

import "./styles.scss";

const defaultPopoverMessage = () => (<div>Please fill in all required fields to proceed</div>);

export const SideMenu = (props) => {
    const {
        globalState,
        setGlobalState,
        modalContent,
        saveFunction,
        modalWidth = "narrow",
        saveStatus,
        confirmationNeeded = false,
    } = props;

    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
        saveFunction();
    };
    const handleCancel = () => {
        setOpen(false);
        setGlobalState(false);
    };

    const [curModalContent, setCurModalContent] = useState(<></>);
    useEffect(() => setCurModalContent(modalContent), [modalContent]);

    const slideLeftClass = "slide-left";
    const slideRightClass = "slide-right";
    const fadeInClass = "m-fadeIn";
    const fadeOutClass = "m-fadeOut";

    const [modalState, setModalState] = useState(false);
    const [modalWidthClass, setModalWidthClass] = useState("");
    const [modalStateClasses, setModalStateClasses] = useState([slideRightClass, fadeOutClass].join(" "));
    const [bkgStateClasses, setBkgStateClasses] = useState(fadeOutClass);

    useEffect(() => {
        setModalState(globalState);
        setModalWidthClass(modalWidth === "wide" ? "modal-wide" : "");

        switch (modalState) {
            case true:
                setModalStateClasses([slideLeftClass, fadeInClass].join(" "))
                setBkgStateClasses(fadeInClass);
                break;
            case false:
                setModalStateClasses([slideRightClass, fadeOutClass].join(" "));
                setBkgStateClasses(fadeOutClass);
                break;
        }
    });

    const handleModalClose = () => {
        switch (saveStatus) {
            case 1:
            case true:
                showModal();
                break;
            default:
                setModalState(false);
                setGlobalState(false);
        }
    }


    // hide the scrollbar on the main page when the modal window is open
    useEffect(() =>{
        if (modalState) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
      
        return () => {
            document.body.classList.remove('no-scroll');
        };
    },[modalState])


    return (
        <div className="modal-area">
            <div className={`modal-window ${modalWidthClass} ${modalStateClasses}`}>
                {/* <div className="modal-window-cross" onClick={handleModalClose}><X size={36} /></div> */}
                <div className="modal-content-cc">
                    <div className="modal-elems">{curModalContent}</div>
                </div>
                <div className="modal-buttons-area">
                    <div className="modal-buttons">
                        {saveStatus || !confirmationNeeded ?
                            <div className={`btn btn-primary save-btn`} onClick={saveFunction}>Save</div>
                            :
                            <Popover content={defaultPopoverMessage} trigger="hover">
                                <div>
                                    <div className={`btn save-btn disabled`}>Save</div>
                                </div>
                            </Popover>
                        }

                    </div>
                </div>
            </div>
            <div className={`bkg-shad ${bkgStateClasses}`} onClick={handleModalClose}></div>
            <Modal
                open={open}
                title="Save your changes"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <Flex gap={5}>
                        <CancelBtn />
                        <OkBtn />
                    </Flex>
                )}
            >
                <p>Do you want to save your changes before closing the window?</p>
            </Modal>
        </div>
    );
};
