import { React, useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Person, Buildings, Coin, ShieldLock } from "react-bootstrap-icons";

import { Space } from "antd";

import ProfileService from "../../services/profile.service";
import { AuthContext } from "../../services/auth.service";
import LoadingScreen from "../../components/elements/LoadingScreen";

import PageHeader from "../../components/elements/PageHeader";
import { SideMenu } from "../../components/layouts/MainLayout/SideMenu";
import { InteractivePanel } from "../../components/layouts/MainLayout/InteractivePanel";

import {
    ProfileDetails,
    CompanyDetails,
    BillingDetails,
} from "./configModals";

import { useMessage } from "../../components/elements/MessageCenter";

/**
 * Saving data function
 * 
 * Sending request to save profile data for user, company and billing profiles.
 * 
 * @param {Object} props Parameters for the checkup
 * @param {Number} props.companyId Company ID
 * @param {String} props.firstName First Name
 * @param {String} props.lastName Last Name
 * @param {String} props.phoneNum Phone number
 * @param {String} props.email Email
 * @param {String} props.companyName COmpany Name
 * @param {String} props.chamberOfCommerceNumber Chamber of Commerce Number
 * @param {String} props.vatNumber VAT Number
 * @param {String} props.invoiceRefNumber Invoice Reference Number
 * @param {String} props.billingFullName Billing - Full name
 * @param {String} props.billingEmail Billing - Email
 * @param {String} props.billingStreet1 Billing - Street 1
 * @param {String} props.billingStreet2 Billing - Street 2
 * @param {String} props.billingPostcode Billing - Postcode
 * @param {String} props.billingCity Billing - City
 * @param {String} props.billingCountry Billing - Country
 * @param {Function} props.modalSaveFunction Function to send data to backend
 * @param {Function} props.handlePanelClick Function to imitate clicking on panel
 * @param {Function} props.setLoadingActive Function to swtich loading page component
 * @param {Function} props.success Function to call a popup success message
 * @param {Function} props.error Function to call a popup error message
 * @param {Function} props.warning Function to call a popup warning message
 * @param {Function} props.setModalSaveStatus Function to set status for the save button inside side menu
 * 
 * @returns {null}
 */
export const SaveConfigData = async (params) => {
    const {
        modalSaveFunction,
        handlePanelClick,
        setLoadingActive,
        success,
        error,
        setModalSaveStatus,
    } = params;

    setLoadingActive(true);

    params.fullName = (params?.firstName + " " + params?.lastName).trim();

    const resp = await modalSaveFunction(params);
    const respData = await resp?.json() || resp;

    switch (respData?.result) {
        case true:
            success("Profile data successfully saved!");
            break;
        default:
            error();
    }

    handlePanelClick("empty");
    setModalSaveStatus(false);

    setLoadingActive(false);
}

const refreshUserData = (props) => {
    const {
        profileData,
        setFirstName,
        setLastName,
        setEmail,
    } = props;

    // Updating state variables
    setFirstName(profileData?.first_name);
    setLastName(profileData?.last_name);
    setEmail(profileData?.email || "");
}

const refreshCompanyData = (props) => {
    const {
        profileData,
        setCompanyName,
        setChamberOfCommerceNumber,
        setVatNumber,
        setBillingStreet1,
        setBillingStreet2,
        setBillingPostcode,
        setBillingProvince,
        setBillingCity,
        setBillingCountry,
    } = props;

    // Updating state variables
    setCompanyName(profileData?.display_company_name || "");
    setChamberOfCommerceNumber(profileData?.company_cocn_number || "");
    setVatNumber(profileData?.company_vat_number || "");
    setBillingStreet1(profileData?.billing_street_address_1 || "");
    setBillingStreet2(profileData?.billing_street_address_2 || "");
    setBillingPostcode(profileData?.billing_postal_code || "");
    setBillingProvince(profileData?.billing_province || "");
    setBillingCity(profileData?.billing_city || "");
    setBillingCountry(profileData?.billing_country || "");
}

const refreshBillingData = (props) => {
    const {
        profileData,
        setBillingFullName,
        setBillingEmail,
        setInvoiceRefNumber,
    } = props;

    // Updating state variables
    setBillingFullName(profileData?.billing_fullname || "");
    setBillingEmail(profileData?.billing_email || "");
    setInvoiceRefNumber(profileData?.company_invoice_ref_number || "");
}


export const ProfileDetailsContext = createContext();
export const CompanyDetailsContext = createContext();
export const BillingDetailsContext = createContext();

/**
 * Account settings page component
 * 
 * @returns {ReactNode} Component with account settings content
 */
export const AccountSettings = () => {
    const navigate = useNavigate();

    // Using popup messages
    const { success, warning, error } = useMessage();

    // Using authentication context to get company info
    const { companyId, companyInfo } = useContext(AuthContext);
    const [currCompanyInfo, setCurrCompanyInfo] = useState(companyInfo);

    // Defining states for side menu
    const [sideMenuState, setSideMenuState] = useState(false);
    const [currentModal, setCurrentModal] = useState(<></>);
    const [curModalSaveFunc, setCurModalSaveFunc] = useState("");
    const [modalSaveStatus, setModalSaveStatus] = useState(false);

    // Controlling loading window status
    const [loadingActive, setLoadingActive] = useState(true);

    // Account properties - user, company and billing profiles
    const [userData, setUserData] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [billingData, setBillingData] = useState({});

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    const [companyName, setCompanyName] = useState("");
    const [chamberOfCommerceNumber, setChamberOfCommerceNumber] = useState("");
    const [vatNumber, setVatNumber] = useState("");
    const [billingStreet1, setBillingStreet1] = useState("");
    const [billingStreet2, setBillingStreet2] = useState("");
    const [billingPostcode, setBillingPostcode] = useState("");
    const [billingProvince, setBillingProvince] = useState("");
    const [billingCity, setBillingCity] = useState("");
    const [billingCountry, setBillingCountry] = useState("");

    const [billingFullName, setBillingFullName] = useState("");
    const [billingEmail, setBillingEmail] = useState("");
    const [invoiceRefNumber, setInvoiceRefNumber] = useState("");

    // Initial page loading. Calling every time use changing the company profile
    useEffect(() => {
        (async () => {
            // Showing the loading screen while we retrieving the data from backend
            setLoadingActive(true);

            setSideMenuState(false);

            // Hiding the loading screen and aborting execution if there"s no company chosen 
            if (!companyId) {
                setLoadingActive(false);
                return;
            }

            // Retrieving the data from backend
            const reqObj = { companyId };

            const response = await ProfileService.getCompanyProfileInfo(reqObj);
            const respData = await response.json();

            const context = respData?.context;
            const userProfileData = context?.user_profile;
            const companyProfileData = context?.company_profile;
            const billingProfileData = context?.billing_profile;

            setUserData(userProfileData);
            setCompanyData(companyProfileData);
            setBillingData(billingProfileData);

            refreshUserData({
                profileData: userProfileData,
                setFirstName,
                setLastName,
                setEmail,
            });

            refreshCompanyData({
                profileData: companyProfileData,
                setCompanyName,
                setChamberOfCommerceNumber,
                setVatNumber,
                setBillingStreet1,
                setBillingStreet2,
                setBillingPostcode,
                setBillingProvince,
                setBillingCity,
                setBillingCountry,
            });

            refreshBillingData({
                profileData: billingProfileData,
                setBillingFullName,
                setBillingEmail,
                setInvoiceRefNumber,
            });

            // Hiding the loading screen
            setLoadingActive(false);
        })();
    }, [currCompanyInfo]);

    // Update chosen company info
    useEffect(() => setCurrCompanyInfo(companyInfo), [companyInfo]);

    // Context providers for each side menu options
    const modalFunc = {
        "empty": <></>,
        "profile_details":
            <ProfileDetailsContext.Provider value={{
                firstName,
                lastName,
                email,
                setFirstName,
                setLastName,
                setEmail,
                companyId,
                setModalSaveStatus,
            }}>
                <ProfileDetails context={ProfileDetailsContext} />
            </ProfileDetailsContext.Provider>,
        "company_details":
            <CompanyDetailsContext.Provider value={{
                companyName,
                chamberOfCommerceNumber,
                vatNumber,
                billingStreet1,
                billingStreet2,
                billingPostcode,
                billingProvince,
                billingCity,
                billingCountry,
                setCompanyName,
                setChamberOfCommerceNumber,
                setVatNumber,
                setBillingStreet1,
                setBillingStreet2,
                setBillingPostcode,
                setBillingProvince,
                setBillingCity,
                setBillingCountry,
                companyId,
                setModalSaveStatus,
            }}>
                <CompanyDetails context={CompanyDetailsContext} />
            </CompanyDetailsContext.Provider>,
        "billing_details":
            <BillingDetailsContext.Provider value={{
                billingFullName,
                billingEmail,
                invoiceRefNumber,
                setBillingFullName,
                setBillingEmail,
                setInvoiceRefNumber,
                companyId,
                setModalSaveStatus,
            }}>
                <BillingDetails context={BillingDetailsContext} />
            </BillingDetailsContext.Provider>,
    }

    // Handling a click on one of panels to update the side menu data 
    const handlePanelClick = (page) => {
        setSideMenuState(!sideMenuState);
        setCurrentModal(modalFunc[page]);
        setCurModalSaveFunc(page);
    }

    // Returning page content
    return (
        <>
            {/* Initializing side menu functionality */}
            <SideMenu
                globalState={sideMenuState}
                setGlobalState={setSideMenuState}
                modalContent={currentModal}
                saveFunction={() => SaveConfigData({
                    companyId,
                    firstName,
                    lastName,
                    email,
                    companyName,
                    chamberOfCommerceNumber,
                    vatNumber,
                    invoiceRefNumber,
                    billingFullName,
                    billingEmail,
                    billingStreet1,
                    billingStreet2,
                    billingPostcode,
                    billingProvince,
                    billingCity,
                    billingCountry,
                    modalSaveFunction: ProfileService.saveProfileData,
                    handlePanelClick,
                    setLoadingActive,
                    curModalSaveFunc,
                    success,
                    error,
                    warning,
                    setModalSaveStatus,
                })}
                saveStatus={modalSaveStatus}
                confirmationNeeded={true}
            />
            <Space block="true">
                {/* Defining loading screen */}
                <LoadingScreen isActive={loadingActive} />
                <Space direction="vertical" size="large" block="true">
                    <PageHeader categoryName="profile" pageName="Account Settings" />
                    <Space direction="vertical" className="container-rows">
                        <InteractivePanel
                            icon={<Person size={21} />}
                            headerText="Personal details"
                            descriptionText="Personal details linked to your account."
                            available={true}
                            clickFunction={() => handlePanelClick("profile_details")}
                        />
                        <InteractivePanel
                            icon={<Buildings size={21} />}
                            headerText="Company details"
                            descriptionText="Company name, VAT number, Chamber of Commerce number and Invoice reference number."
                            available={true}
                            clickFunction={() => handlePanelClick("company_details")}
                        />
                        <InteractivePanel
                            icon={<Coin size={21} />}
                            headerText="Billing details"
                            descriptionText="Your company billing details."
                            available={true}
                            clickFunction={() => handlePanelClick("billing_details")}
                        />
                        <InteractivePanel
                            icon={<ShieldLock size={21} />}
                            headerText="Reset password"
                            descriptionText="Change your account password."
                            available={true}
                            clickFunction={() => navigate("/password-reset")}
                        />
                    </Space>
                </Space>
            </Space>
        </>
    );
}
