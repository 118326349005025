import { React, useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FiletypeDoc } from 'react-bootstrap-icons';
import { Input } from 'antd';

export const EmailsNotifModal = ({ context }) => {
    const {
        notifImplementationLink,
        enableEmailNotif,
        setEnableEmailNotif,
        curEmailsList,
        setCurEmailsList,
        setConfigParams,
    } = useContext(context);

    const [notifStatus, setNotifStatus] = useState(enableEmailNotif);
    const [emailsList, setEmailsList] = useState(curEmailsList);
    const [inputValue, setInputValue] = useState("");
    const [configParamsToSave, setConfigParamsToSave] = useState({});

    useEffect(() => {
        setNotifStatus(enableEmailNotif);
        setEmailsList(curEmailsList);
    }, [enableEmailNotif, curEmailsList]);
    
    const handleNotifStatusChange = () => {
        setNotifStatus(!notifStatus);
        setEnableEmailNotif(!notifStatus);
    }

    const handleInputChange = (event) => {
        const enterKeys = [";", ",", "Enter"];

        if (enterKeys.indexOf(event?.key) > -1 && inputValue !== "") {
            const newList = [ ...emailsList, inputValue.trim().replace(";", "") ];
            setEmailsList(newList);
            setCurEmailsList(newList);
            setInputValue("");
        }
        else if (enterKeys.indexOf(event.target.value) < 0) setInputValue(event.target.value);
    }

    const handleRemoveTag = (elem) => {
        const newList = emailsList.filter(email => email !== elem);
        setEmailsList(newList);
        setCurEmailsList(newList);
    }

    useEffect(() => {
        const newObject = { ...configParamsToSave };
        newObject.email_updates = notifStatus;
        newObject.emails = emailsList.join(";");
        setConfigParamsToSave(newObject);
    }, [notifStatus, emailsList]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return(
        <div className="modal-elems-rows">
            <div>
                <h5 className='small-heading'>CONFIGURATION</h5>
                <h2>Email notifications</h2>
            </div>
            <div className="container-guide modal-panel-button-row">
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={notifImplementationLink}>
                        <div className="file-icon" style={{ color: '$cc-blue' }}>
                            <FiletypeDoc size={30} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up email notifications</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" checked={notifStatus} onChange={handleNotifStatusChange} />
                    <label className="form-check-label" htmlFor="email_notif_enabled">Enable notifications</label>
                </div>
            </div>
            <div>
                <h3 className="modal-category">Email list</h3>
                <p className='pb-3'>
                    Add the email addresses from the contacts
                    that should receive the mail notifications below. 
                    <br></br>
                    Separate the emails by entering a comma or 
                    by pressing the enter button.
                </p>
                <div id="email_notif_container" className="tags-container">
                    <Input className='wide-input' type="email" placeholder="Add emails" value={inputValue} onChange={handleInputChange} onKeyDown={handleInputChange} />
                    <div id="email_notif_list" className="tags-list">
                        { emailsList.map((elem, idx) => {
                            if (elem !== "")
                                return(
                                    <span className="tag" key={idx}>
                                        {elem}
                                        <span className="remove" onClick={() => handleRemoveTag(elem)}>x</span>
                                    </span>)
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
