import { React, useState, useEffect, createContext, useContext } from 'react';
import { Display, Stopwatch, HddStack, Envelope, Slack, MicrosoftTeams, Tools, WindowDesktop, Binoculars } from 'react-bootstrap-icons';

import ConfigService from '../../services/config.service';
import ProductSettingsService from '../../services/product.service';
import { AuthContext } from '../../services/auth.service';
import LoadingScreen from '../../components/elements/LoadingScreen';

import "./styles.scss";

import PageHeader from '../../components/elements/PageHeader';
import { SideMenu } from '../../components/layouts/MainLayout/SideMenu';
import { InteractivePanel } from "../../components/layouts/MainLayout/InteractivePanel";
import { EmailsNotifModal } from '../../components/layouts/MainLayout/SideMenu/EmailNotifModal';
import { SlackNotifModal } from '../../components/layouts/MainLayout/SideMenu/SlackNotifModal';
import { TeamsNotifModal } from '../../components/layouts/MainLayout/SideMenu/TeamsNotifModal';

import {
    ProductSettingsModal,
    CSTSettingsModal,
    SSTSettingsModal,
    TagInactivitySettingsModal,
    CloudMonitoringSettingsModal
} from "./configModals";

import { useMessage } from "../../components/elements/MessageCenter";

const _ = require('lodash');

// Save function
export const SaveConfigData = async (params) => {
    const {
        companyId,
        currentConfig,
        setCurrentConfig,
        configParams,
        modalSaveFunction,
        handlePanelClick,
        setLoadingActive,
        success,
        error,
    } = params;

    setLoadingActive(true);

    const configObj = { ...currentConfig };

    Object.keys(configParams).forEach(key => {
        configObj[key] = configParams[key];
    });

    const resp = await modalSaveFunction({ configObj, companyId });
    const respData = await resp?.json() || resp;

    switch (respData?.result) {
        case true:
            success("Configuration data successfully saved!");
            setCurrentConfig(configObj);
            break;
        default:
            error();
    }

    handlePanelClick("empty");
    setLoadingActive(false);
}

export const ProductSettingsContext = createContext();
export const CSTSettingsContext = createContext();
export const SSTSettingsContext = createContext();
export const TagInactivitySettingsContext = createContext();
export const cloudRunMonitoringContext = createContext();
export const EmailsNotifContext = createContext();
export const SlackNotifContext = createContext();
export const TeamsNotifContext = createContext();

const productDescLink = `https://docs.code-cube.io/`;
const CSImplmenetationLink = `https://docs.code-cube.io/tag-monitor/error-monitoring-client-side/`;
const SSImplmenetationLink = `https://docs.code-cube.io/tag-monitor/error-monitoring-server-side/`;
const TIImplmenetationLink = `https://docs.code-cube.io/tag-monitor/tag-inactivity/`;
const clientSideTemplateLink = `https://storage.googleapis.com/portal_dev_bucket/gtm_templates/Code%20Cube%20-%20Client-side%20Tag%20Monitor%20template.tpl`;
const serverSideTemplateLink = `https://storage.googleapis.com/portal_dev_bucket/gtm_templates/Code%20Cube%20-%20Server-side%20Tag%20Monitor%20template.tpl`;
const notifImplementationLink = `https://docs.code-cube.io/notifications/`;

export const TagMonitorConfig = () => {
    const { companyId, companyInfo } = useContext(AuthContext);
    const [currCompanyInfo, setCurrCompanyInfo] = useState(companyInfo);
    
    // Using popup messages
    const { success, warning, error } = useMessage();

    const [sideMenuState, setSideMenuState] = useState(false);
    const [currentModal, setCurrentModal] = useState(<></>);

    const [loadingActive, setLoadingActive] = useState(true);

    const [currentConfig, setCurrentConfig] = useState({});
    const [configParams, setConfigParams] = useState({});

    const [clientSideAvailable, setClientSideAvailable] = useState(false);
    const [serverSideAvailable, setServerSideAvailable] = useState(false);
    const [tagInactivityAvailable, setTagInactivityAvailable] = useState(false);
    const [emailNotifAvailable, setEmailNotifAvailable] = useState(false);
    const [slackNotifAvailable, setSlackNotifAvailable] = useState(false);
    const [teamsNotifAvailable, setTeamsNotifAvailable] = useState(false);

    const [companyDomains, setCompanyDomains] = useState([]);
    const [gtmContainers, setGtmContainers] = useState([]);

    const [timezones, setTimezones] = useState([]);
    const [timezoneName, setTimezoneName] = useState("");
    const [curTimezone, setCurTimezone] = useState(null);

    const [databaseName, setDatabaseName] = useState("");
    const [clientErrorsThreshold, setClientErrorsThreshold] = useState(1);
    const [serverErrorsThreshold, setServerErrorsThreshold] = useState(1);

    const [tagsClientSide, setTagsClientSide] = useState("");
    const [tagsServerSide, setTagsServerSide] = useState("");
    const [checkFrequency, setCheckFrequency] = useState("");
    const [checkFreqOptions, setCheckFreqOptions] = useState([]);

    const [enableEmailNotif, setEnableEmailNotif] = useState(false);
    const [curEmailsList, setCurEmailsList] = useState([]);

    const [enableSlackNotif, setEnableSlackNotif] = useState(false);
    const [slackChannel, setSlackChannel] = useState("");
    const [slackWorkspaceId, setSlackWorkspaceId] = useState("");
    const [slackWebhook, setSlackWebhook] = useState("");

    const [enableTeamsNotif, setEnableTeamsNotif] = useState(false);
    const [teamsChannel, setTeamsChannel] = useState("");
    const [teamsWebhook, setTeamsWebhook] = useState("");

    const [ customNmbrCid, setCustomNmbrCid ] = useState("");
    const [ subscriptionModel, setSubscriptionModel ] = useState("");
    const [ cloudRunData, setCloudRunData ] = useState({});

    // Reloading the page content only if the new company profile is different
    useEffect(() => _.isEqual(companyInfo, currCompanyInfo) ? undefined : setCurrCompanyInfo(companyInfo), [companyInfo]);

    useEffect(() => {
        (async () => {
        
            setLoadingActive(true);      
            
            const resp = await ConfigService.getTagMonitorConfigData(companyId);
            const respData = await resp.json();

            const cloudRunData = await ConfigService.getCloudRunMonitoringData(companyId);
            const cloudRunDataResponseData = await cloudRunData.json();
            setCloudRunData(cloudRunDataResponseData || {});
        
            setCurrentConfig(respData?.config_data?.current_config);    
            // Product settings data
            setTimezones(respData?.config_data?.timezones || []);
            setTimezoneName(respData?.config_data?.current_config.timezone_name || "");
            setCurTimezone(respData?.config_data?.current_config?.timezone || "");
            setCompanyDomains(respData?.config_data?.current_config?.company_domains || []);
            setGtmContainers(respData?.config_data?.current_config?.gtm_containers || []);

            setDatabaseName(respData?.config_data?.current_config?.database_name);
            setClientErrorsThreshold(respData?.config_data?.current_config?.client_errors_threshold);
            setServerErrorsThreshold(respData?.config_data?.current_config?.server_errors_threshold);
            setLoadingActive(true);
            setSideMenuState(false);

            setCurrentConfig(respData?.config_data?.current_config);
            // Product settings data
            setTimezones(respData?.config_data?.timezones || []);
            setTimezoneName(respData?.config_data?.current_config.timezone_name || "");
            setCurTimezone(respData?.config_data?.current_config?.timezone || "");
            setCompanyDomains(respData?.config_data?.current_config?.company_domains || []);
            setGtmContainers(respData?.config_data?.current_config?.gtm_containers || []);

            setDatabaseName(respData?.config_data?.current_config?.database_name);
            setClientErrorsThreshold(respData?.config_data?.current_config?.client_errors_threshold);
            setServerErrorsThreshold(respData?.config_data?.current_config?.server_errors_threshold);

            setCustomNmbrCid(respData?.config_data?.current_config.custom_nmbr_cid);
            setSubscriptionModel(respData?.config_data?.current_config.subscription_package);

            // Tag Inactivity
            const tagsClientSide = respData?.config_data?.current_config?.tags_client_side;
            const tagsServerSide = respData?.config_data?.current_config?.tags_server_side;
            setTagsClientSide(tagsClientSide?.split(",") || []);
            setTagsServerSide(tagsServerSide?.split(",") || []);
            setCheckFrequency(respData?.config_data?.current_config?.check_frequency);
            setCheckFreqOptions(respData?.config_data?.tag_inactivity_check_freq);

            // Email notifications data
            setEnableEmailNotif(respData?.config_data?.current_config?.email_updates || false);
            setCurEmailsList(respData?.config_data?.current_config?.emails?.split(";") || []);

            // Slack notifications data
            setEnableSlackNotif(respData?.config_data?.current_config?.slack_updates || false);
            setSlackChannel(respData?.config_data?.current_config?.slack_channel || "");
            setSlackWorkspaceId(respData?.config_data?.current_config?.slack_workspace_id || "");
            setSlackWebhook(respData?.config_data?.slack_webhook || "");

            // Teams notifications data
            setEnableTeamsNotif(respData?.config_data?.current_config?.teams_updates || false);
            setTeamsChannel(respData?.config_data?.current_config?.teams_channel || "");
            setTeamsWebhook(respData?.config_data?.current_config?.teams_webhook || "");

            const subLevel = companyInfo?.tag_monitor_subscription_model?.toLowerCase();
            const settings = await ProductSettingsService.getProductSettings();
            const subSettings = settings?.tag_monitor_settings[subLevel];
            setClientSideAvailable(subSettings?.client_side_monitoring);
            setServerSideAvailable(subSettings?.server_side_monitoring);
            setTagInactivityAvailable(subSettings?.tag_inactivity);
            setEmailNotifAvailable(subSettings?.email_notif);
            setSlackNotifAvailable(subSettings?.slack_notif);
            setTeamsNotifAvailable(subSettings?.teams_notif);
            setLoadingActive(false);

        })();

    }, [currCompanyInfo]);

    const modalFunc = {
        "empty": <></>,
        "general":
            <ProductSettingsContext.Provider value={{
                productDescLink,
                timezones,
                timezoneName,
                curTimezone,
                setCurTimezone,
                companyDomains,
                gtmContainers,
                setGtmContainers,
                setCompanyDomains,
                setConfigParams,
                serverSideAvailable,
                customNmbrCid,
                subscriptionModel
            }}>
                <ProductSettingsModal context={ProductSettingsContext} />
            </ProductSettingsContext.Provider>,
        "cst_monitoring":
            <CSTSettingsContext.Provider value={{
                databaseName,
                CSImplmenetationLink,
                clientSideTemplateLink,
                clientErrorsThreshold,
                setClientErrorsThreshold,
                setConfigParams
            }}>
                <CSTSettingsModal context={CSTSettingsContext} />
            </CSTSettingsContext.Provider>,
        "sst_monitoring":
            <SSTSettingsContext.Provider value={{
                databaseName,
                SSImplmenetationLink,
                serverSideTemplateLink,
                serverErrorsThreshold,
                setServerErrorsThreshold,
                setConfigParams,
            }}>
                <SSTSettingsModal context={SSTSettingsContext} />
            </SSTSettingsContext.Provider>,
        "tag_inactivity":
            <TagInactivitySettingsContext.Provider value={{
                TIImplmenetationLink,
                tagsClientSide,
                tagsServerSide,
                setTagsClientSide,
                setTagsServerSide,
                companyDomains,
                setCompanyDomains,
                checkFrequency,
                setCheckFrequency,
                checkFreqOptions,
                setConfigParams,
            }}>
                <TagInactivitySettingsModal context={TagInactivitySettingsContext} />
            </TagInactivitySettingsContext.Provider>,

        "cloud_run": 
            <cloudRunMonitoringContext.Provider value={{companyId ,cloudRunData, companyInfo}}>
                <CloudMonitoringSettingsModal context={cloudRunMonitoringContext} />
            </cloudRunMonitoringContext.Provider>,

        "email_notif":
            <EmailsNotifContext.Provider value={{
                notifImplementationLink,
                enableEmailNotif,
                curEmailsList,
                setEnableEmailNotif,
                setCurEmailsList,
                setConfigParams,
            }}>
                <EmailsNotifModal context={EmailsNotifContext} />
            </EmailsNotifContext.Provider>,
        "slack_notif":
            <SlackNotifContext.Provider value={{
                notifImplementationLink,
                enableSlackNotif,
                slackChannel,
                slackWorkspaceId,
                slackWebhook,
                setEnableSlackNotif,
                setSlackChannel,
                setSlackWorkspaceId,
                setConfigParams,
            }}>
                <SlackNotifModal context={SlackNotifContext} />
            </SlackNotifContext.Provider>,
        "teams_notif":
            <TeamsNotifContext.Provider value={{
                notifImplementationLink,
                enableTeamsNotif,
                teamsChannel,
                teamsWebhook,
                setEnableTeamsNotif,
                setTeamsChannel,
                setTeamsWebhook,
                setConfigParams,
            }}>
                <TeamsNotifModal context={TeamsNotifContext} />
            </TeamsNotifContext.Provider>,
    }

    const handlePanelClick = (page) => {

        setSideMenuState(!sideMenuState);
        setCurrentModal(modalFunc[page]);
    }

    return (
        <>
            <SideMenu
                globalState={sideMenuState}
                setGlobalState={setSideMenuState}
                modalContent={currentModal}
                saveFunction={() => SaveConfigData({
                    companyId,
                    currentConfig,
                    setCurrentConfig,
                    configParams,
                    modalSaveFunction: ConfigService.saveTagMonitorConfigData,
                    handlePanelClick,
                    setLoadingActive,
                    success,
                    error,
                    warning,
                })}
            />
            <div className="row">
                <LoadingScreen isActive={loadingActive} />
                <div className="col-12 col-sm-10 col-md-8 text-start">
                    <div>
                        <div className="row">
                            <PageHeader categoryName="configuration" pageName="Tag Monitor" />
                            <div className="container-rows mt-4">
                                <div>
                                    <InteractivePanel
                                        icon={<Tools size={21} />}
                                        headerText="Product settings"
                                        descriptionText="Configure all basic product settings for your Tag Manager"
                                        available={true}
                                        clickFunction={() => handlePanelClick("general")}

                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<WindowDesktop size={21} />}
                                        headerText="Client-side error monitoring"
                                        descriptionText="Configure settings for client-side error monitoring"
                                        available={clientSideAvailable}
                                        clickFunction={() => handlePanelClick("cst_monitoring")}

                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<HddStack size={21} />}
                                        headerText="Server-side error monitoring"
                                        descriptionText="Configure settings for server-side error monitoring"
                                        available={serverSideAvailable}
                                        clickFunction={() => handlePanelClick("sst_monitoring")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<Stopwatch size={21} />}
                                        headerText="Tag inactivity"
                                        descriptionText="Configure settings for tag inactivity"
                                        available={tagInactivityAvailable}
                                        clickFunction={() => handlePanelClick("tag_inactivity")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<Binoculars size={21} />}
                                        headerText="Monitoring via Google Cloud Platform"
                                        descriptionText="Configure settings for monitoring implementation in Google Cloud Platform"
                                        available={serverSideAvailable}
                                        clickFunction={() => handlePanelClick("cloud_run")}
                                    />
                                </div>
                            </div>
                            <h3 style={{ fontSize: '1em' }} className="page-category">NOTIFICATION SETTINGS</h3>
                            <div className="container-rows">
                                <div>
                                    <InteractivePanel
                                        icon={<Envelope size={21} />}
                                        headerText="Email Notifications"
                                        descriptionText="Configure settings to receive notifications via mail"
                                        available={emailNotifAvailable}
                                        clickFunction={() => handlePanelClick("email_notif")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<Slack size={21} />}
                                        headerText="Slack notifications"
                                        descriptionText="Configure settings to receive notifications via Slack"
                                        available={slackNotifAvailable}
                                        clickFunction={() => handlePanelClick("slack_notif")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<MicrosoftTeams size={21} />}
                                        headerText="Teams notifications"
                                        descriptionText="Configure settings to receive notifications via Teams"
                                        available={teamsNotifAvailable}
                                        clickFunction={() => handlePanelClick("teams_notif")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
