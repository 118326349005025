import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { BarChart, Gear, PersonCircle, QuestionCircle } from 'react-bootstrap-icons';
import companyLogoIconPath from '../../../../assets/code-cube-logo.svg';
import companyLogoNamePath from '../../../../assets/code-cube-name.svg';
import "./styles.scss";

import { AuthContext } from '../../../../services/auth.service';

import MenuCategory from "./MenuCategory";
import ProfileArea from "./ProfileArea";
import Dropdown from '../../../elements/Dropdown';

const Sidebar = () => {
    const { companyInfo, companyList } = useContext(AuthContext);
    const { adminUser } = useContext(AuthContext);
    const [tagMonitorActive, setTagMonitorActive] = useState(false);
    const [dataLayerGuardActive, setDataLayerGuardActive] = useState(false);
    const [sitespeedMonitorActive, setSitespeedMonitorActive] = useState(false);

    useEffect(() => {
        setTagMonitorActive(companyInfo?.tag_monitor);
        setDataLayerGuardActive(companyInfo?.datalayer_guard);
        setSitespeedMonitorActive(companyInfo?.sitespeed_monitor);
    }, [companyInfo]);


    const dashboardLinks = [
        { name: "DataLayer Guard", to: "/datalayer_guard_dashboard", active: dataLayerGuardActive },
        { name: "Tag Monitor", to: "/tag_monitor_dashboard", active: tagMonitorActive },
    ];

    const configLinks = [
        { name: "DataLayer Guard", to: "/datalayer_guard_config", active: dataLayerGuardActive },
        { name: "Sitespeed Monitor", to: "/sitespeed_monitor_config", active: sitespeedMonitorActive },
        { name: "Tag Monitor", to: "/tag_monitor_config", active: tagMonitorActive },
    ];

    const accountSettingsLinks = [
        { name: "Account settings", to: "/account_settings", active: true },
        { name: "Billing and payments", to: "/", active: false },
        { name: "Team members", to: "/", active: false },
    ];

    if (adminUser) {
        accountSettingsLinks.push(
            { name: "Create Datasets", to: "/create_dataset", active: true }
        )
    }

    const supportLinks = [
        { name: "Documentation", to: "https://docs.code-cube.io/", active: true },
        { name: "Requests", to: "/", active: false },
    ];

    const [hovered, handleHovering] = useState(false);
    const [sidebarClass, handleSidebarClass] = useState();
    const hoverSidebar = () => handleHovering(true);
    const hoverOutSidebar = () => handleHovering(false);

    useEffect(() => {
        handleSidebarClass(`sidebar-menu ${hovered ? "hovered" : ""}`);
    }, [hovered]);

    return (
        <div className={sidebarClass} onMouseEnter={hoverSidebar} onMouseLeave={hoverOutSidebar}>
            <div className="sidebar-container">
                <div className="sidebar-container-top mt-5">
                    <div className="sidebar-logo-area">
                        <Link className="sidebar-logo" to="/" onClick={() => hoverOutSidebar()}>
                            <div className="sidebar-logo-container">
                                <img className="sidebar-logo-icon" src={companyLogoIconPath} alt="Code Cube Logo" height="26" />
                                <img className="sidebar-logo-name title-sliding" src={companyLogoNamePath} alt="Code Cube" height="16" />
                            </div>
                        </Link>
                    </div>
                    <div className="sidebar-elems-area links-area">
                        <MenuCategory
                            categoryIcon={<BarChart size={20} />}
                            categoryName={"Dashboards"}
                            links={dashboardLinks}
                            hovered={hovered}
                            hoverOutSidebar={hoverOutSidebar}
                        />
                        <MenuCategory
                            categoryIcon={<Gear size={20} />}
                            categoryName={"Configurations"}
                            links={configLinks}
                            hovered={hovered}
                            hoverOutSidebar={hoverOutSidebar}
                        />
                        <MenuCategory
                            categoryIcon={<PersonCircle size={20} />}
                            categoryName={"Account settings"}
                            links={accountSettingsLinks}
                            hovered={hovered}
                            hoverOutSidebar={hoverOutSidebar}
                        />
                        <MenuCategory
                            categoryIcon={<QuestionCircle size={20} />}
                            categoryName={"Support"}
                            links={supportLinks}
                            hovered={hovered}
                            hoverOutSidebar={hoverOutSidebar}
                        />
                    </div>
                </div>
                <div className="sidebar-container-bottom">
                    <div className="sidebar-elems-area">
                        {companyList?.length > 1 ?
                            <Dropdown
                                list={companyList}
                                hovered={hovered}
                                hoverOutSidebar={hoverOutSidebar}
                            />
                            :
                            <></>
                        }

                        <ProfileArea
                            hovered={hovered}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
