import React, { useMemo, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

import styles from "./styles.scss";

// Define a custom style for DataTable
const customStyles = {
    rows: {
        style: {
            left: 0,
            minHeight: "2rem", // Set the minimum height for rows
            textWrap: "balance",
            transition: `backgroundColor ${styles.backgroundAnimationSpeed} ease-in-out`,

            '&:hover': {
                backgroundColor: styles.ccLightGrey,
                cursor: "pointer",
            },
        },
    },
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
            paddingLeft: '8px', // Define the padding for header cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            fontSize: '14px',
            paddingLeft: '8px', // Define the padding for cells
            paddingRight: '8px',
            wordBreak: "break-all",
            textWrap: "balance",
        },
    },
};

const FilterComponent = ({ filterText, onFilter }) => (
    <input
        id="search"
        type="text"
        placeholder="Filter By Tag name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
    />
);

const TableComponent = (params) => {
    const {
        columns,
        tableData,
        rowClickFunction,
        filterParam
    } = params;

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const [filteredItems, setFilteredItems] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const updatedColumns = useMemo(() => [
        {
            name: '',
            cell: (row, index) => ((currentPage - 1) * perPage) + index + 1,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '4%',
            style: {
                justifyContent: "left",
            },
        },
        ...columns,
    ], [currentPage, perPage, columns]);

    useEffect(() => {
        if (filterText !== "") {
            setFilteredItems(tableData.filter(
                item => item[filterParam] && item[filterParam].toLowerCase().includes(filterText.toLowerCase()),
            ));
        }
        else {
            setFilteredItems(tableData);
        }
    }, [tableData, filterText]);

    // const subHeaderComponentMemo = useMemo(() => {
    //     const handleClear = () => {
    //         if (filterText) {
    //             setResetPaginationToggle(!resetPaginationToggle);
    //             setFilterText('');
    //         }
    //     };

    //     return (
    //         <FilterComponent
    //             onFilter={e => setFilterText(e.target.value)}
    //             onClear={handleClear}
    //             filterText={filterText}
    //         />
    //     );
    // }, [filterText, resetPaginationToggle]);

    return (
        <DataTable
            columns={updatedColumns}
            data={filteredItems}
            customStyles={customStyles}
            pagination
            subHeader
            // subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            selectableRows={false}
            onRowClicked={(row, index) => rowClickFunction(row)}
            onChangePage={page => setCurrentPage(page)}
            onChangeRowsPerPage={(newPerPage, page) => {
                setPerPage(newPerPage);
                setCurrentPage(page);
            }}
        />
    );
};

export default TableComponent;
