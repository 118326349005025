import React, { useState, useEffect, useContext } from 'react';
import { Input, Space, Flex, Typography } from 'antd';
const { Text, Link } = Typography;

const modalHeaderName = ("settings");

// Side menu content for Profile Details
export const ProfileDetails = ({ context }) => {
    const {
        firstName,
        lastName,
        email,
        setFirstName,
        setLastName,
        setEmail,
        companyId,
        setModalSaveStatus,
    } = useContext(context);

    const [curFirstName, setCurFirstName] = useState(firstName);
    const [curLastName, setCurLastName] = useState(lastName);
    const [curEmail, setCurEmail] = useState(email);

    const [firstNameStatus, setFirstNameStatus] = useState(firstName?.length > 0);
    const [lastNameStatus, setLastNameStatus] = useState(lastName?.length > 0);
    const [emailStatus, setEmailStatus] = useState(email?.length > 0);

    const [saveStatus, setSaveStatus] = useState(false);

    useEffect(() => {
        setFirstNameStatus(curFirstName?.length > 0);
        setFirstName(curFirstName);
        setSaveStatus(curFirstName?.length > 0 & lastNameStatus & emailStatus);
    }, [curFirstName]);
    useEffect(() => {
        setLastNameStatus(curLastName?.length > 0);
        setLastName(curLastName);
        setSaveStatus(firstNameStatus & curLastName?.length > 0 & emailStatus);
    }, [curLastName]);
    useEffect(() => {
        setEmailStatus(curEmail?.length > 0);
        setEmail(curEmail);
        setSaveStatus(firstNameStatus & lastNameStatus & curEmail?.length > 0);
    }, [curEmail]);

    useEffect(() => setSaveStatus(false), []);
    
    useEffect(() => setModalSaveStatus(saveStatus), [saveStatus]);

    return (
        <Space key={companyId} direction="vertical" size="large" block="true">
            <Space direction="vertical" size="small" block="true">
                <h5 className='small-heading'>{modalHeaderName}</h5>
                <h2 className='header-letter-spacing'>Personal details</h2>
                <p>Fill in your personal details, which will be linked to your Code Cube account.</p>
            </Space>
            <Space direction="vertical" size="large" block="true">
                <Space direction="vertical" size="small" block="true">
                    <Text strong>First name *</Text>
                    <Input
                        value={curFirstName}
                        onChange={(e) => setCurFirstName(e.target.value)}
                        status={firstNameStatus ? "" : "error"}
                    />
                </Space>
                <Space direction="vertical" size="small" block="true">
                    <Text strong>Last name *</Text>
                    <Input
                        value={curLastName}
                        onChange={(e) => setCurLastName(e.target.value)}
                        status={lastNameStatus ? "" : "error"}
                    />
                </Space>
                <Space direction="vertical" size="small" block="true">
                    <Text strong>Email *</Text>
                    <Text>Add your personal email address, this email will also be used as your username in the portal.</Text>
                    <Space direction="vertical" size="small" block="true">
                        <Input
                            placeholder="hello@code-cube.io"
                            value={curEmail}
                            type="email"
                            onChange={(e) => setCurEmail(e.target.value)}
                            status={emailStatus ? "" : "error"}
                        />
                    </Space>
                </Space>
            </Space>
        </Space>
    );
};

// Side menu content for Company Details
export const CompanyDetails = ({ context }) => {
    const {
        companyName,
        chamberOfCommerceNumber,
        vatNumber,
        billingStreet1,
        billingStreet2,
        billingPostcode,
        billingProvince,
        billingCity,
        billingCountry,
        setCompanyName,
        setChamberOfCommerceNumber,
        setVatNumber,
        setBillingStreet1,
        setBillingStreet2,
        setBillingPostcode,
        setBillingProvince,
        setBillingCity,
        setBillingCountry,
        companyId,
        setModalSaveStatus,
    } = useContext(context);

    const [curCompanyName, setCurCompanyName] = useState(companyName);
    const [curChamberOfCommerceNumber, setCurChamberOfCommerceNumber] = useState(chamberOfCommerceNumber);
    const [curVatNumber, setCurVatNumber] = useState(vatNumber);
    const [curBillingStreet1, setCurBillingStreet1] = useState(billingStreet1);
    const [curBillingStreet2, setCurBillingStreet2] = useState(billingStreet2);
    const [curBillingPostcode, setCurBillingPostcode] = useState(billingPostcode);
    const [curBillingProvince, setCurBillingProvince] = useState(billingProvince);
    const [curBillingCity, setCurBillingCity] = useState(billingCity);
    const [curBillingCountry, setCurBillingCountry] = useState(billingCountry);

    const [companyNameStatus, setCompanyNameStatus] = useState(companyName?.length > 0);
    const [chamberOfCommerceStatus, setChamberOfCommerceStatus] = useState(chamberOfCommerceNumber?.length > 0);
    const [billingStreet1Status, setBillingStreet1Status] = useState(billingStreet1?.length > 0);

    const [saveStatus, setSaveStatus] = useState(false);

    useEffect(() => {
        setCompanyName(curCompanyName);
        setCompanyNameStatus(curCompanyName?.length > 0);

        setSaveStatus((curCompanyName?.length > 0) & chamberOfCommerceStatus & billingStreet1Status);
    }, [curCompanyName]);
    useEffect(() => {
        setChamberOfCommerceNumber(curChamberOfCommerceNumber);
        setChamberOfCommerceStatus(curChamberOfCommerceNumber?.length > 0);
        
        setSaveStatus(companyNameStatus & (curChamberOfCommerceNumber?.length > 0) & billingStreet1Status);
    }, [curChamberOfCommerceNumber]);
    useEffect(() => {
        setBillingStreet1(curBillingStreet1);
        setBillingStreet1Status(curBillingStreet1?.length > 0);

        setSaveStatus(companyNameStatus & chamberOfCommerceStatus & (curBillingStreet1?.length > 0));
    }, [curBillingStreet1]);

    useEffect(() => setVatNumber(curVatNumber), [curVatNumber]);
    useEffect(() => setBillingStreet2(curBillingStreet2), [curBillingStreet2]);
    useEffect(() => setBillingPostcode(curBillingPostcode), [curBillingPostcode]);
    useEffect(() => setBillingProvince(curBillingProvince), [curBillingProvince]);
    useEffect(() => setBillingCity(curBillingCity), [curBillingCity]);
    useEffect(() => setBillingCountry(curBillingCountry), [curBillingCountry]);

    useEffect(() => setSaveStatus(false), []);
    
    useEffect(() => setModalSaveStatus(saveStatus), [saveStatus]);

    return (
        <Space key={companyId} direction="vertical" size={50}>
            <Space direction="vertical" size="small" block="true">
                <Space direction="vertical" size="small" block="true">
                    <h5 className='small-heading'>{modalHeaderName}</h5>
                    <h2 className='header-letter-spacing'>Company details</h2>
                    <p>Fill in the relevant company details in the fields below.</p>
                </Space>

                <Space direction="vertical" size="middle" block="true">
                    <Space direction="vertical" size="small" block="true">
                        <Text strong>Company name *</Text>
                        <Space.Compact size="middle">
                            <Input
                                value={curCompanyName}
                                onChange={(e) => setCurCompanyName(e.target.value)}
                                status={companyNameStatus ? "" : "error"}
                            />
                        </Space.Compact>
                    </Space>

                    <Space direction="horizontal" size={50} block="true">
                        <Space direction="vertical" size="small" block="true">
                            <Text strong>Chamber of commerce *</Text>
                            <Space.Compact size="middle">
                                <Input
                                    value={curChamberOfCommerceNumber}
                                    onChange={(e) => setCurChamberOfCommerceNumber(e.target.value)}
                                    status={chamberOfCommerceStatus ? "" : "error"}
                                />
                            </Space.Compact>
                        </Space>
                        <Space direction="vertical" size="small" block="true">
                            <Text strong>VAT number</Text>
                            <Space.Compact size="middle">
                                <Input
                                    value={curVatNumber}
                                    onChange={(e) => setCurVatNumber(e.target.value)}
                                />
                            </Space.Compact>
                        </Space>
                    </Space>

                </Space>
            </Space>

            <Space direction="vertical" size="large" block="true">
                <Space direction="vertical" size="small" block="true">
                    <h2 className='header-letter-spacing'>Company address</h2>
                    <p>Fill the information about the company address in the fields below.</p>
                </Space>

                <Space direction="vertical" size="middle" block="true">
                    <Space direction="vertical" size="small" block="true">
                        <Text strong>Street address *</Text>
                        <Space.Compact size="middle">
                            <Input
                                value={curBillingStreet1}
                                onChange={(e) => setCurBillingStreet1(e.target.value)}
                                status={billingStreet1Status ? "" : "error"}
                            />
                        </Space.Compact>
                    </Space>
                    <Space direction="vertical" size="small" block="true">
                        <Text strong>Street address 2</Text>
                        <Space.Compact size="middle">
                            <Input
                                value={curBillingStreet2}
                                onChange={(e) => setCurBillingStreet2(e.target.value)}
                            />
                        </Space.Compact>
                    </Space>

                    <Space direction="horizontal" size={50} block="true">
                        <Space direction="vertical" size="small" block="true">
                            <Text strong>Postal Code</Text>
                            <Space.Compact size="middle">
                                <Input
                                    value={curBillingPostcode}
                                    onChange={(e) => setCurBillingPostcode(e.target.value)}
                                />
                            </Space.Compact>
                        </Space>
                        <Space direction="vertical" size="small" block="true">
                            <Text strong>State / Province</Text>
                            <Space.Compact size="middle">
                                <Input
                                    value={curBillingProvince}
                                    onChange={(e) => setCurBillingProvince(e.target.value)}
                                />
                            </Space.Compact>
                        </Space>
                    </Space>

                    <Space direction="horizontal" size={50} block="true">
                        <Space direction="vertical" size="small" block="true">
                            <Text strong>Country</Text>
                            <Space.Compact size="middle">
                                <Input
                                    value={curBillingCountry}
                                    onChange={(e) => setCurBillingCountry(e.target.value)}
                                />
                            </Space.Compact>
                        </Space>
                        <Space direction="vertical" size="small" block="true">
                            <Text strong>City</Text>
                            <Space.Compact size="middle">
                                <Input
                                    value={curBillingCity}
                                    onChange={(e) => setCurBillingCity(e.target.value)}
                                />
                            </Space.Compact>
                        </Space>
                    </Space>

                </Space>
            </Space>
        </Space>
    );
}

// Side menu content for Billing Details
export const BillingDetails = ({ context }) => {
    const {
        billingFullName,
        billingEmail,
        invoiceRefNumber,
        setBillingFullName,
        setBillingEmail,
        setInvoiceRefNumber,
        companyId,
        setModalSaveStatus,
    } = useContext(context);

    const [curBillingFullName, setCurBillingFullName] = useState(billingFullName);
    const [curBillingEmail, setCurBillingEmail] = useState(billingEmail);
    const [curInvoiceRefNumber, setCurInvoiceRefNumber] = useState(invoiceRefNumber);

    const [fullNameStatus, setFullNameStatus] = useState(billingFullName?.length > 0);
    const [emailStatus, setEmailStatus] = useState(billingEmail?.length > 0);

    const [saveStatus, setSaveStatus] = useState(false);

    useEffect(() => {
        setBillingFullName(curBillingFullName);
        setFullNameStatus(curBillingFullName?.length > 0);

        setSaveStatus((curBillingFullName?.length > 0) & emailStatus);
    }, [curBillingFullName]);
    useEffect(() => {
        setBillingEmail(curBillingEmail);
        setEmailStatus(curBillingEmail?.length > 0);

        setSaveStatus((curBillingEmail?.length > 0) & fullNameStatus);
    }, [curBillingEmail]);

    useEffect(() => setInvoiceRefNumber(curInvoiceRefNumber), [curInvoiceRefNumber]);

    useEffect(() => setSaveStatus(false), []);
    
    useEffect(() => setModalSaveStatus(saveStatus), [saveStatus]);

    return (
        <Space key={companyId} direction="vertical" size="large" block="true">
            <Space direction="vertical" size="small" block="true">
                <h5 className='small-heading'>{modalHeaderName}</h5>
                <h2 className='header-letter-spacing'>Billing Details</h2>
                <p>Please add the details for invoicing in the fields below.</p>
            </Space>
            <Space direction="vertical" size="middle">
                <Space direction="vertical" size="small" block="true">
                    <Typography.Title level={5}>Full name</Typography.Title>
                    <Text strong>Invoicing contact person (first and last name) *</Text>
                    <Space.Compact size="middle">
                        <Input
                            value={curBillingFullName}
                            onChange={(e) => setCurBillingFullName(e.target.value)}
                            status={fullNameStatus ? "" : "error"}
                        />
                    </Space.Compact>
                </Space>
                <Space direction="vertical" size="small" block="true">
                    <Text strong>Invoicing mail address *</Text>
                    <Space.Compact size="middle">
                        <Input
                            value={curBillingEmail}
                            onChange={(e) => setCurBillingEmail(e.target.value)}
                            status={emailStatus ? "" : "error"}
                        />
                    </Space.Compact>
                </Space>
                <Space direction="vertical" size="small" block="true">
                    <Text strong>PO number / Reference</Text>
                    <Space.Compact size="middle">
                        <Input
                            value={curInvoiceRefNumber}
                            onChange={(e) => setCurInvoiceRefNumber(e.target.value)}
                        />
                    </Space.Compact>
                </Space>
            </Space>
        </Space>
    );
}
