import FetchService from './fetch.service';
import localStorageService from './localStorage.service';

const fetchService = new FetchService();

class DashboardService {

    async checkSession() {

        const token = localStorageService.getToken();

        const response = fetchService(`/check_session/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify({ "token": token }),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        return response;
    }


    async getDLGDashboardData(params = {}) {
        let {
            companyId,
            domain,
            start_date,
            end_date,
            event_name,
            differenceOneDayOrOneHour
        } = params;

        if (!companyId) companyId = localStorageService.getCompanyId();

        const reqData = {
            company_id: companyId,
            domain,
            start_date,
            end_date,
            event_name,
            difference_one_day_or_one_hour: differenceOneDayOrOneHour
        };

        const response = fetchService(`/dashboard/datalayer_guard_dashboard/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(reqData),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        return response;
    }

    async getTagMonitorDashboard(params = {}, chartType) {

        let {
            companyId,
            startDate,
            endDate,
            chosenContainerId,
            chosenEventName,
            chosenDomain,
            searchUrlValue,
            tagNamesList,
            differenceOneDayOrOneHour,
            filtersType
        } = params;

        if (!companyId) companyId = localStorageService.getCompanyId();

        const reqData = {
            company_id: companyId,
            start_date: startDate,
            end_date: endDate,
            container_id: chosenContainerId,
            event_name: chosenEventName,
            domain: chosenDomain,
            url: searchUrlValue,
            tag_names_list: tagNamesList,
            difference_one_day_or_one_hour: differenceOneDayOrOneHour,
            chart_type: chartType,
            filters_type: filtersType
        };

        const response = fetchService(`/dashboard/tag_monitor_dashboard/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(reqData),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });



        return response;
    }
}

export default new DashboardService();
