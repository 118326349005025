import { React, useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiletypeDoc, DashCircle, InfoCircleFill,Download, Copy } from 'react-bootstrap-icons';
import { TimezoneDropdown } from '../../components/elements/TimezoneDropdown';
import { useNavigate } from 'react-router-dom';
import { Select, Popover, Flex, Spin , Alert } from 'antd';
import GoogleCloudConfigForm from './GoogleCloudConfigFile';
import ConfigService from '../../services/config.service';
import { LoadingOutlined, CloseSquareFilled  } from '@ant-design/icons';

export const ProductSettingsModal = ({ context }) => {
    const {
        productDescLink,
        timezones,
        timezoneName,
        curTimezone,
        setCurTimezone,
        companyDomains,
        gtmContainers,
        setGtmContainers,
        setCompanyDomains,
        setConfigParams,
        serverSideAvailable,
        customNmbrCid,
        subscriptionModel
    } = useContext(context);

    const [choosenTimezone, setChoosenTimezone] = useState(curTimezone);
    const [curCompanyDomains, setCurCompanyDomains] = useState(()=>{
        return companyDomains.length > 0 ? companyDomains : [""];
    });
    
    const [configParamsToSave, setConfigParamsToSave] = useState({});
    const [ curGTMContainers, setCurGTMContainers ] = useState(()=>{
        return gtmContainers.length > 0 ? gtmContainers : [{ type: "", container: "" }];
    });


    const [ showGuideDivDomain, setShowGuideDivDomain ] = useState(false);
    const [ showGuideDivGTM, setShowGuideDivGTM ] = useState(false);

    let maxNmbrCids;

    const content = (
        <div>
          <p>
            You have reached the maximum number of containers  <br></br>
            allowed by your subscription.  <br></br>
            To add more containers or upgrade your plan, <br></br>
            please contact us
            <a target='blank' href='https://code-cube.io/tag-monitor/upgrade-license/'> here</a>.
            </p>
        </div>
      );

    // Set the maximum number of containers per subscription model
    if(customNmbrCid !== 0){
        maxNmbrCids= customNmbrCid;
    } else {
        switch(subscriptionModel){
            case "essentials":
                maxNmbrCids = 1;
                break;
            case "advanced":
                maxNmbrCids = 3;
                break;
            case "premium":
                maxNmbrCids = 10;
                break;
            default:
        }
    }
    
    const [ addMoreCidDisabled, setAddMoreCidDisabled ] = useState("");
    const [ trigger, setTrigger ] = useState("");

    useEffect(() => {
        if(curGTMContainers?.length >= maxNmbrCids){
            setAddMoreCidDisabled("disabledBtn");
            setTrigger("hover");

        } else {
            setAddMoreCidDisabled("");
            setTrigger("");
        }
    },[maxNmbrCids, gtmContainers, curGTMContainers])


    useEffect(() => {

        setChoosenTimezone(curTimezone);
        // setCurCompanyDomains(companyDomains || []);
        setCurCompanyDomains(() => companyDomains.length > 0 ? companyDomains : [""]);
        setCurGTMContainers(() => gtmContainers.length > 0 ? gtmContainers : [{ type: "", container: "" }]);

    }, [curTimezone, companyDomains]);


    const handleTimezoneChange = (newTimezone) => {
        setCurTimezone(newTimezone);
        setChoosenTimezone(newTimezone);
    }


    const handleElemDelete = (idx) => {
        const newDomainsList = curCompanyDomains?.filter((elem, index) => index !== idx);
        setCurCompanyDomains(newDomainsList);
        setCompanyDomains(newDomainsList)
    }

    const handleElemDeleteGTM = (idx) => {
        const newGtmContainerList = curGTMContainers.filter((elem, index) => index !== idx);
        setGtmContainers(newGtmContainerList);
        setCurGTMContainers(newGtmContainerList);
    }

    const handleElemAdd = () => {

        setCurCompanyDomains([ ...curCompanyDomains, {} ]);
        setCompanyDomains([ ...curCompanyDomains, {} ]);
        
    }
    
    const handleElemAddGTM = () => {

        if(curGTMContainers?.length < maxNmbrCids){
            setCurGTMContainers([ ...curGTMContainers, {} ]);
            setGtmContainers([ ...gtmContainers, {} ]);
        }
    }

    const handleInputValueChange = (idx, field, event) => {
        const newDomainsList = [ ...curCompanyDomains ];
        const elem = { ...newDomainsList[idx] };
        elem[field] = event.target.value;
        newDomainsList[idx] = elem;
        setCurCompanyDomains(newDomainsList);
        setCompanyDomains(newDomainsList)
    }

    const handleGTMContainerInputValueChange = (idx, event, valueType) => {
        const newGTMContainerList = [ ...curGTMContainers ];
        const elem = { ...newGTMContainerList[idx] };

            switch (valueType) {
                case "cidType":
                    elem['type'] = event.target.innerText;
                    break;
                case "gtmID":
                    elem['container'] = event.target.value;
                    elem['type'] = 'client-side';
                    break;
                default:
            }
            
        
        newGTMContainerList[idx] = elem;
        setCurGTMContainers(newGTMContainerList);
        setGtmContainers(newGTMContainerList);

    }


    useEffect(() => {
        setConfigParamsToSave(prevParams => {
            const newObject = { 
                ...prevParams, 
                timezone: choosenTimezone, 
                company_domains: curCompanyDomains, 
                gtm_containers : curGTMContainers 
            };

            return newObject;
        });
    }, [choosenTimezone, curCompanyDomains, curGTMContainers]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return(
        <div className="modal-elems-rows">
            <div>
                <h5 className='small-heading'>CONFIGURATION</h5>
                <h2>Product Settings</h2>
            </div>
            <div style={{ marginBottom: 0 }} className="container-guide modal-panel-button-row">
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={productDescLink}>
                        <div className="file-icon" style={{ color: '$cc-blue' }}>
                            <FiletypeDoc size={30} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up in Tag Manager</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <p style={{  marginBottom: 0 }}>
                    Configure the settings below to start the configuration of the Tag Monitor.
                    These are basic settings which will reflect on the complete implementation.
                </p>    
            </div>
            <div style={{ position: 'relative' }} className="elems-list">
                { showGuideDivDomain &&
                    <div className='hoverable-div-parent' style={{ left: '6.8em' }}>
                        <p className='hover-content'>
                            Only the domain(s) added to this list will be
                            activated for alerting and will be shown in the 
                            dashboards.
                        </p>
                    </div>
                }
                <h3 style={{ marginBottom : 0 }} className="modal-category">
                    Domains
                    <InfoCircleFill 
                        size={15} 
                        className='info-icon' 
                        onMouseEnter={() => setShowGuideDivDomain(true)}
                        onMouseLeave={() => setShowGuideDivDomain(false)}
                    />
                </h3>
                <p style={{ marginBottom : 0 }}>Add the domain(s) for which you would 
                    like to activate the Tag Monitor in the list below.</p>
                <div className="list-col-2-add">
                    { curCompanyDomains?.map((elem, idx) =>
                            <div key={idx} className="inputs-row-3-col-single d-flex flex-row">
                                <input type="text" placeholder="code-cube.io" value={elem.domain || ""} onChange={(event) => handleInputValueChange(idx, "domain", event)} />
                                <div>
                                    <DashCircle className='color-blue' size={18} onClick={() => handleElemDelete(idx)}></DashCircle>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div>
                    <div className="btn btn-sm cc-btn-outline-blue" onClick={handleElemAdd}>+ add domain</div>
                </div>
            </div>

            <div style={{ position: 'relative' }} className="elems-list">
                <h3 className="modal-category" style={{ marginBottom : 0 }}>
                    Tag Manager containers
                    <InfoCircleFill 
                    size={15} 
                    className='info-icon'
                    onMouseEnter={() => setShowGuideDivGTM(true)}
                    onMouseLeave={() => setShowGuideDivGTM(false)}
                     />
                </h3>
                
                {
                    showGuideDivGTM && 
                    <div className='hoverable-div-parent' style={{ left: '15em' }}>
                        <p className='hover-content'>
                            Only the container(s) added to this list will be 
                            activated for alerting and will show up in the
                            dashboards.
                        </p>
                    </div>
                }
                <p style={{ marginBottom : 0 }}>
                    Add the container ID’s for which you would like to activate the Tag Monitor 
                    in the list below. Add both the client- and server-side container(s), 
                    related to each domain where monitoring should be enabled.              
                </p>
                <div className="list-col-2-add">
                    {curGTMContainers?.map((elem, idx) =>
                            <div key={idx} className="inputs-row-3-col-single d-flex flex-row">
                                <input type="text" placeholder="GTM-XXXXX" value={elem?.container || ""} onChange={(event) => handleGTMContainerInputValueChange(idx, event, 'gtmID')} />

                                <div className='gtm-cid-container'>
                                    <div className="dropdown">
                                        <button className="dropdown-toggle btn-sm text-start"
                                            type="button" id="gtm-cid" data-bs-toggle="dropdown" aria-expanded="false">
                                            {elem?.type || "client-side"}
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="gtm-cid">
                                            <li className="pointer-on-hover dropdown-item" onClick={(event)=>handleGTMContainerInputValueChange(idx, event, 'cidType')}>
                                                client-side
                                            </li>

                                            <li 
                                                // className="pointer-on-hover dropdown-item {}" 
                                                className={`pointer-on-hover dropdown-item ${!serverSideAvailable &&  'disabled'}`}
                                                onClick={(event)=>handleGTMContainerInputValueChange(idx, event, 'cidType')}
                                            >
                                                server-side
                                            </li>                 
                                            
                                            
                                        </ul>
                                    </div>
                                </div>

                                <div>
                                    <DashCircle
                                        className='color-blue' 
                                        size={18} 
                                        onClick={() => handleElemDeleteGTM(idx)}>

                                    </DashCircle>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div>
                    <Popover content={content} title="Container Limit Reached!" trigger={trigger}>

                        <div className={`${addMoreCidDisabled} btn btn-sm cc-btn-outline-blue`} 
                            onClick={handleElemAddGTM}
                        >
                            + add container
                        </div>
                    </Popover>
                </div>
            </div>


            <TimezoneDropdown 
                choosenTimezone= {choosenTimezone}
                timezones = {timezones}
                timezoneName = {timezoneName}
                handleTimezoneChange = {handleTimezoneChange}
            />


        </div>
    );

}

export const CSTSettingsModal = ({ context }) => {
    const {
        databaseName,
        CSImplmenetationLink,
        clientSideTemplateLink,
        clientErrorsThreshold,
        setClientErrorsThreshold,
        setConfigParams,
    } = useContext(context);
    const navigate = useNavigate();

    const percentages = [ 1, 5, 10, 25, 50, 100 ];

    const [curThreshold, setCurThreshold] = useState(clientErrorsThreshold);
    const [configParamsToSave, setConfigParamsToSave] = useState({});
    const [ showGuideDiv, setShowGuideDiv ] = useState(false);
    const inputRefDataset = useRef(null);

    const copyToClipboard = (text) => {
        const inputValue = text.current.value;
        navigator.clipboard.writeText(inputValue);
    }


    useEffect(() => {
        setCurThreshold(clientErrorsThreshold);
    }, [clientErrorsThreshold]);

    const handleThresholdChange = (elem) => {
        setCurThreshold(elem);
        setClientErrorsThreshold(elem);
    }

    useEffect(() => {
        const newObject = { ...configParamsToSave };
        newObject.client_errors_threshold = curThreshold;
        setConfigParamsToSave(newObject);
    }, [curThreshold]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return(
        <div className="modal-elems-rows">
            <div>
                <h5 className='small-heading'>CONFIGURATION</h5>
                <h2>Client-side error monitoring</h2>
            </div>
            <div className="container-guide modal-panel-button-row d-flex flex-row">
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={CSImplmenetationLink}>
    
                        <div className="file-icon" style={{ color: 'rgba(var($cc-blue), 1)' }}>
                            <FiletypeDoc size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up in Tag Manager</div>
                        </div>
                    </Link>  
                </div>
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={clientSideTemplateLink}>
                        <div className="file-icon" style={{ color: 'rgba(var($cc-turquoise), 1)' }}>
                            <Download size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Download template</div>
                            <div className="panel-button-desc">For GTM client-side container</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <p>
                    To enable monitoring, additional configuration in your client-side Tag Manager container is required.
                    Please follow the steps in the documentation to complete the implementation.
                </p>

            </div>

            <div>
                <h3 className="modal-category">Database name for your configuration</h3>
                <p>
                    When implementing the new monitoring tag, the field “Dataset name” needs to be added manually. 
                    Copy the name in the field below and add this value to your tag template. 
                </p>

                <div className='d-flex flex-row' style={{ gap: '2em' }}>
                    <div>
                        <p className="modal-category fw-bold">Dataset name</p>
                        <div>
                            <input 
                                className='disabled-input' 
                                type="text" 
                                placeholder="Database name" 
                                value={databaseName || ""} 
                                disabled 
                                ref={inputRefDataset}
                            />
                            <Copy 
                                className='copy-icon' 
                                size={21}
                                onClick={()=> copyToClipboard(inputRefDataset)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <h3 className="modal-category">Bulk editing tag names</h3>
                <p>
                    By default, the Tag Monitor only retrieves the Tag ID from the Tag Manager container. To make the 
                    configuration more user friendly, we would like to add the metadata “Tag Name” to each tag. 
                </p>
                <p>
                    Please follow the steps via the button below to enable bulk editing in your container.
                </p>
                <div
                    className="btn btn-sm cc-btn-outline-blue mb-3" 
                    onClick={(e) => {e.preventDefault(); navigate("/authorize_user")}}
                >
                    start bulk editing
                </div>

            </div>

            <div style={{ position : 'relative' }}>
                <h3 className="modal-category">
                    Threshold for error notifications
                    <InfoCircleFill 
                        size={15} 
                        className='info-icon' 
                        onMouseEnter={() => setShowGuideDiv(true)}
                        onMouseLeave={() => setShowGuideDiv(false)}
                    />
                </h3>
                {
                    showGuideDiv && 
                    <div className='hoverable-div-parent' style={{ left: '20em' }}>
                        <p className='hover-content'>
                            Notifications via email, Slack, or Teams are triggered only
                            when the error threshold is met
                        </p>
                    </div>
                }
                <p>
                    Choose the error percentage threshold to determine when you should receive notifications.
                </p>

                <div className="dropdown">
                    <button className="dropdown-toggle text-start" style={{ color: 'grey' }}
                        type="button" id="cst_threshold_select" data-bs-toggle="dropdown" aria-expanded="false">
                        {curThreshold}%
                    </button>
                    <ul style={{ minWidth: '8em' }} className="dropdown-menu" aria-labelledby="cst_threshold_select">
                        { percentages.map((elem, key) => 
                            <li className="pointer-on-hover" key={key} onClick={() => handleThresholdChange(elem)}>
                                <div className="dropdown-item">{elem}%</div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export const SSTSettingsModal = ({ context }) => {
    const {
        databaseName,
        SSImplmenetationLink,
        serverSideTemplateLink,
        serverErrorsThreshold,
        setServerErrorsThreshold,
        setConfigParams,
    } = useContext(context);

    const navigate = useNavigate();
    const percentages = [ 1, 5, 10, 25, 50, 100 ];

    const [curThreshold, setCurThreshold] = useState(serverErrorsThreshold);
    const [configParamsToSave, setConfigParamsToSave] = useState({});
    const [ showGuideDiv, setShowGuideDiv ] = useState(false);
    const inputRefTable = useRef(null);
    const inputRefDataset = useRef(null);

    const copyToClipboard = (text) => {
        const inputValue = text.current.value;
        navigator.clipboard.writeText(inputValue);
    }


    useEffect(() => {
        setCurThreshold(serverErrorsThreshold);
    }, [serverErrorsThreshold]);

    const handleThresholdChange = (elem) => {
        setCurThreshold(elem);
        setServerErrorsThreshold(elem);
    }

    useEffect(() => {
        const newObject = { ...configParamsToSave };
        newObject.server_errors_threshold = curThreshold;
        setConfigParamsToSave(newObject);
    }, [curThreshold]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return(
        <div className="modal-elems-rows"> 
            <div>
                <h5 className='small-heading'>CONFIGURATION</h5>
                <h2>Server-side error monitoring</h2>
            </div>
            <div className="container-guide modal-panel-button-row d-flex flex-row">
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={SSImplmenetationLink}>
    
                        <div className="file-icon" style={{ color: 'rgba(var($cc-blue), 1)' }}>
                            <FiletypeDoc size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up in Tag Manager</div>
                        </div>
                    </Link>
                </div>
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={serverSideTemplateLink}>
                        <div className="file-icon" style={{ color: 'rgba(var($cc-turquoise), 1)' }}>
                            <Download size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Download template</div>
                            <div className="panel-button-desc">For GTM server-side container</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <p>
                    To enable monitoring, additional configuration in your server-side Tag Manager container is                   
                    required.Please follow the steps in the documentation to complete the implementation.
                </p>

            </div>
            <div>
                <h3 className="modal-category">Database name for your configuration</h3>
                <p>
                    When implementing the new monitoring tag, the field “Dataset name” needs to be added manually.
                    Copy the name in the field below and add this value to your tag template. 
                </p>

            </div> 

            <div className='d-flex flex-column' style={{ gap: '.5em'}}>
                <div>
                    <p className="modal-category fw-bold">Dataset name</p>
                    <div>
                        <input 
                            className='disabled-input' 
                            type="text" 
                            placeholder="Database name" 
                            value={databaseName || ""} 
                            disabled 
                            ref={inputRefDataset}
                        />
                        <Copy 
                            className='copy-icon' 
                            size={21}
                            onClick={()=> copyToClipboard(inputRefDataset)}
                        />
                    </div>
                </div>
                <div>
                    <p className="modal-category fw-bold">Table name</p>
                    <div>
                        <input 
                            className='disabled-input'
                            type="text" 
                            placeholder="Database name" 
                            value="raw_data_server" 
                            disabled 
                            ref={inputRefTable}
                            />
                        <Copy 
                            className='copy-icon' 
                            size={21} 
                            onClick={()=> copyToClipboard(inputRefTable)}
                        />
                    </div>
                </div>
            </div>

            <div>
                <h3 className="modal-category">Bulk editing tag names</h3>
                <p>
                    By default, the Tag Monitor only retrieves the Tag ID from the Tag Manager container. To make the 
                    configuration more user friendly, we would like to add the metadata “Tag Name” to each tag. 
                </p>
                <p>
                    Please follow the steps via the button below to enable bulk editing in your container.
                </p>
                <div
                    className="btn btn-sm cc-btn-outline-blue mb-3" 
                    onClick={(e) => {e.preventDefault(); navigate("/authorize_user")}}
                >
                    start bulk editing
                </div>

            </div>

            <div style={{ position : 'relative' }}>
                <h3 className="modal-category">
                    Threshold for error notifications
                    <InfoCircleFill 
                        size={15} 
                        className='info-icon' 
                        onMouseEnter={() => setShowGuideDiv(true)}
                        onMouseLeave={() => setShowGuideDiv(false)}
                    />
                </h3>
                {
                    showGuideDiv && 
                    <div className='hoverable-div-parent' style={{ left: '20em' }}>
                        <p className='hover-content'>
                            Notifications via email, Slack, or Teams are triggered only
                            when the error threshold is met
                        </p>
                    </div>
                }
                <p>
                    Choose the error percentage threshold to determine when you should receive notifications.
                </p>

                <div className="dropdown">
                    <button className="dropdown-toggle text-start" style={{ color: 'grey' }}
                        type="button" id="cst_threshold_select" data-bs-toggle="dropdown" aria-expanded="false">
                        {curThreshold}%
                    </button>
                    <ul style={{ minWidth: '8em' }} className="dropdown-menu" aria-labelledby="cst_threshold_select">
                        { percentages.map((elem, key) => 
                            <li className="pointer-on-hover" key={key} onClick={() => handleThresholdChange(elem)}>
                                <div className="dropdown-item">{elem}%</div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export const TagInactivitySettingsModal = ({ context }) => {
    const {
        TIImplmenetationLink,
        tagsClientSide,
        tagsServerSide,
        setTagsClientSide,
        setTagsServerSide,
        companyDomains,
        setCompanyDomains,
        checkFrequency,
        setCheckFrequency,
        checkFreqOptions,
        setConfigParams,
    } = useContext(context);

    const [curClientSideTags, setCurClientSideTags] = useState(tagsClientSide);
    const [curServerSideTags, setCurServerSideTags] = useState(tagsServerSide);
    const [clientTagsInputValue, setClientTagsInputValue] = useState("");
    const [serverTagsInputValue, setServerTagsInputValue] = useState("");
    const [curCompanyDomains, setCurCompanyDomains] = useState(companyDomains);
    const [configParamsToSave, setConfigParamsToSave] = useState({});

    useEffect(() => {
        setCurClientSideTags(tagsClientSide);
        setCurServerSideTags(tagsServerSide);
        setCurCompanyDomains(companyDomains);
    }, [tagsClientSide, tagsServerSide, companyDomains]);

    const handleCheckboxChange = (idx, event) => {
        const newList = [ ...curCompanyDomains ];
        const elem = newList[idx];
        elem.tag_inactivity = event.target.checked;
        newList[idx] = elem;
        setCurCompanyDomains(newList);
        setCompanyDomains(newList);
    }

    const [curCheckFreq, setCurCheckFreq] = useState(checkFrequency);

    const handleCheckFreqChange = (newCheckFreq) => {
        setCurCheckFreq(newCheckFreq);
        setCheckFrequency(newCheckFreq);
    }

    useEffect(()=>{
        setCurCheckFreq(checkFrequency);
    },[checkFrequency])

    // Client-side tags input handlers
    const handleCSTInputChange = (event) => {
        const enterKeys = [";", ",", "Enter", ""];

        if (enterKeys.indexOf(event?.key) > -1 && clientTagsInputValue !== "") {
            const newList = [ ...curClientSideTags, clientTagsInputValue.trim().replace(";", "") ];
            setCurClientSideTags(newList);
            setTagsClientSide(newList);
            setClientTagsInputValue("");
        }
        else if (enterKeys.indexOf(event.target.value) < 0) setClientTagsInputValue(event.target.value);
    }

    const handleRemoveCSTTag = (elem) => {
        const newList = curClientSideTags.filter(email => email !== elem);
        setCurClientSideTags(newList);
        setTagsClientSide(newList);
    }

    // Server-side tags input handlers
    const handleSSTInputChange = (event) => {
        const enterKeys = [";", ",", "Enter", ""];

        if (enterKeys.indexOf(event?.key) > -1 && serverTagsInputValue !== "") {
            const newList = [ ...curServerSideTags, serverTagsInputValue.trim().replace(";", "") ];
            setCurServerSideTags(newList);
            setTagsServerSide(newList);
            setServerTagsInputValue("");
        }
        else if (enterKeys.indexOf(event.target.value) < 0) setServerTagsInputValue(event.target.value);
    }

    const handleRemoveSSTTag = (elem) => {
        const newList = curServerSideTags.filter(email => email !== elem);
        setCurServerSideTags(newList);
        setTagsServerSide(newList);
    }

    useEffect(() => {
        const newObject = { ...configParamsToSave };
        newObject.tags_client_side = curClientSideTags.join(",");
        newObject.tags_server_side = curServerSideTags.join(",");
        newObject.check_frequency = curCheckFreq;
        newObject.company_domains = curCompanyDomains;
        setConfigParamsToSave(newObject);
    }, [curClientSideTags, curServerSideTags, curCheckFreq, curCompanyDomains]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return(
        <div className="modal-elems-rows">
            <div>
                <h5 className='small-heading'>CONFIGURATION</h5>
                <h2>Tag Inactivity</h2>
            </div>
            <div className="container-guide modal-panel-button-row d-flex flex-row">
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={TIImplmenetationLink}>
    
                        <div className="file-icon" style={{ color: 'rgba(var($cc-blue), 1)' }}>
                            <FiletypeDoc size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up in Tag Manager</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <h3 className="modal-category">List of tags (client-side)</h3>
                <p>
                    Add the list of tags which should be enabled for Tag Inactivity monitoring.
                    Separate the tags by entering comma or pressing Enter button.
                </p>
                <div className="tags-container">
                    <input type="text" placeholder="Tag name" value={clientTagsInputValue} onChange={handleCSTInputChange} onKeyDown={handleCSTInputChange} />
                    <div className="tags-list">
                        { curClientSideTags.map((elem, idx) => 
                            <span className="tag" key={idx}>
                                {elem}
                                <span className="remove" onClick={() => handleRemoveCSTTag(elem)}>x</span>
                            </span>)
                        }
                    </div>
                </div>
            </div>
            <div>
                <h3 className="modal-category">List of tags (server-side)</h3>
                <p>
                    Add the list of tags which should be enabled for Tag Inactivity monitoring.
                    Separate the tags by entering comma or pressing Enter button.
                </p>
                <div className="tags-container">
                    <input type="text" placeholder="Tag name" value={serverTagsInputValue} onChange={handleSSTInputChange} onKeyDown={handleSSTInputChange} />
                    <div className="tags-list">
                        { curServerSideTags.map((elem, idx) => 
                            <span className="tag" key={idx}>
                                {elem}
                                <span className="remove" onClick={() => handleRemoveSSTTag(elem)}>x</span>
                            </span>)
                        }
                    </div>
                </div>
            </div>
                <div>
                    <h3 className="modal-category">Domains for monitoring</h3>
                    <p>
                        Add the list of domains which should be enabled for Tag Inactivity monitoring. 
                    </p>
                    {curCompanyDomains && 
                    
                        <div className="list-col-2-add">
                            { curCompanyDomains?.map((elem, idx) => 
                                <div key={idx} className="inputs-row-2-col-checkbox">
                                    <div className="form-check">
                                        <input 
                                            className="form-check-input list-checkbox" 
                                            type="checkbox" 
                                            checked={elem?.tag_inactivity} 
                                            onChange={(event) => handleCheckboxChange(idx, event)} 
                                        />
                                    </div>
                                    <input className='disabled-input' type="text" value={elem?.domain} disabled />
                                </div>
                            ) }
                        </div>
                    }
                </div>
                <div>
                    <h3 className="modal-category">Select frequency</h3>
                        <p>
                            Select the frequency of getting tag inactivity 
                            notifications from the list below.

                        </p>
                    <Select
                        style={{ width: 200 }}
                        placeholder="Select frequency"
                        optionFilterProp="label"
                        value={curCheckFreq}
                        onSelect={(e)=>{handleCheckFreqChange(e)}}
                        options={[
                            {
                                value: 'hourly',
                                label: 'Every hour',
                            },
                            {
                                value: 'every4hours',
                                label: 'Every 4 hours',
                            },
                            {
                                value: 'daily',
                                label: 'Once per day',
                            }
                        ]}
                    />
                </div>
        </div>
    );
}

export const CloudMonitoringSettingsModal =({ context }) =>{
    const { cloudRunData } = useContext(context);
    const { companyInfo } = useContext(context);
    const { companyId } = useContext(context);
    const [ googleCloudDataForm, setGoogleCloudDataForm ] = useState({});
    const [ response, setResponse ] = useState({});
    const [ loadingActive, setloadingActive ] = useState(false);
    const [ alertData, setAlertData ] = useState({});
    const [ formData ,setFormData ] = useState(cloudRunData);
    const tagMonitorSubscription = companyInfo["tag_monitor_subscription_model"];
    const googleCloudImplementationDocsLink = "https://docs.code-cube.io/tag-monitor/cloud-run-monitoring/";

    useEffect(() => {

        const saveData = async () =>{
            setloadingActive(true);
            const resp = await ConfigService.saveGoogleCloudData({ googleCloudDataForm, companyId });
            const respData = await resp?.json() || resp;
            setResponse(respData);
            setloadingActive(false);
            return respData;
        }

        if (Object.keys(googleCloudDataForm).length > 0){
            saveData();
        }

    }, [googleCloudDataForm]);


    useEffect(()=>{

        setGoogleCloudDataForm({});
        setResponse({});
        setloadingActive(false);
        setAlertData({});
        setFormData(cloudRunData);

    
    },[companyId, cloudRunData]);


    useEffect(() =>{
        const error = response.error;
        const details = response.details == "None" ? "" : response.details;
        const success = response.success;
        const type = success ? "success" : "error";
        const message = success ? "Successfull implementation!" : "Error occured!";

        setAlertData(
            {
                'error': error,
                'details':details, 
                'type': type, 
                'message':message
            }
        );

    }, [response]);


    return(
        <div className="modal-elems-rows">
            <div id='cloud-run-div'>
                <div>
                    <h5 className='small-heading'>CONFIGURATION</h5>
                    <h2>Monitoring via Google Cloud Platform</h2>
                </div>

                <div className="container-guide modal-panel-button-row d-flex flex-row mt-4 mb-5">
                    <Link className="panel-button-container grey-bg" target="_blank" to={googleCloudImplementationDocsLink}>
    
                        <div className="file-icon" style={{ color: 'rgba(var($cc-blue), 1)' }}>
                            <FiletypeDoc size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up the configuration</div>
                        </div>
                    </Link>
                </div>

                <div className='mb-5'>
                    <p>
                        Are you hosting your GTM server-side environment using Cloud Run on the Google Cloud Platform? 
                        If so, we can enable additional monitoring features for you:
                    </p>
                    <ul>
                        <li>
                            Monitor your Cloud Run tagging instances.
                        </li>
                        <li>
                            Receive insights in server-side error logs for more detailed error descriptions.
                        </li>
                    </ul>

                    <p>
                        Please fill in the fields below to activate monitoring in your Google 
                        Cloud Platform hosting the server-side 
                        
                        Google Tag Manager instances. 
                        More information can be found in the implementation guide.
                    </p>
                </div>

                <GoogleCloudConfigForm 
                    cloudRunData={formData} 
                    saveFunction={setGoogleCloudDataForm} 
                    tagMonitorSubscription={tagMonitorSubscription}
                />

                {loadingActive && (
                    <Flex align="center" gap="middle">
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 48, marginBottom: "1rem" }} spin />} />
                    </Flex>
                )} 

                {
                    Object.keys(response).length > 0 && !loadingActive &&
                    <Alert
                        message={alertData.message}
                        description={alertData.details}
                        type={alertData.type}
                    />
                }
            </div>
        </div>
    );
}