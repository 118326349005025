import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import "./styles.scss";
import LoadingScreen from '../../components/elements/LoadingScreen';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

// aggregate data per consent param
const aggregateData = (data) => {
    const aggregatedData = data.reduce((acc, current) => {
        if (!acc[current.consent_name]) {
            acc[current.consent_name] = { granted: 0, denied: 0 };
        }
        acc[current.consent_name].granted += current.granted;
        acc[current.consent_name].denied += current.denied;
        return acc;
    }, {});

    return Object.keys(aggregatedData).map(consentName => ({
        consent_name: consentName,
        granted: aggregatedData[consentName].granted,
        denied: aggregatedData[consentName].denied,
    }));
};


const DoughnutChart = (props) => {

    const {data} = props;
    const [ chartData , setChartData ] = useState([]);
    const { consentMonitoringLoading } = props;


    useEffect(() =>{
        const chartlist = [];
        const aggregatedData = aggregateData(data);

        aggregatedData?.forEach((consent)=>{
            const percentageDenied = (consent.denied / (consent.denied + consent.granted) * 100).toFixed(2);
            const percentageGranted = (consent.granted / (consent.denied + consent.granted) * 100).toFixed(2);
            
            const data = {
                labels : [],
                datasets: [
                    {
                        data: [
                            consent.granted,
                            consent.denied,
                        ],
                        backgroundColor: ['rgb(43, 43, 181)', 'rgba(255, 102, 31, 0.15)'],
                        borderColor: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)'],
                        borderWidth:30,
                        label : consent.consent_name
                    },
                ],
                title : consent.consent_name,
                weight: 2,
                percentageDenied : percentageDenied,
                percentageGranted: percentageGranted


            };
            chartlist.push(data);
        })

        // order consent params
        const order = [
            "ad_storage",
            "analytics_storage",
            "ad_user_data",
            "ad_personalization",
            "functionality_storage",
            "security_storage",
            "personalization_storage"
        ];

        const sortedData = chartlist.sort((a, b) => {
            return order.indexOf(a.title) - order.indexOf(b.title);
        });

        setChartData(sortedData);
    }, [data]);

    const options = {cutout : "75%"};

    // Add label for the % granted inside the doughnut charts
    const doughnutLabel = {
        id: 'doughnutLabel',
        afterDatasetsDraw(chart, args, plugins){
            const { ctx , data} = chart;

            const granted = data.datasets[0].data[0];
            const denied = data.datasets[0].data[1];

            const percentageGranted = (granted / (denied + granted) * 100).toFixed(2);
            const legend = isNaN(percentageGranted) ? 'not set' : `${percentageGranted}%`;

            const centerX = chart.getDatasetMeta(0).data[0].x;
            const centerY = chart.getDatasetMeta(0).data[0].y;

            // text
            ctx.save();
            ctx.font = 'bold 1rem Inter';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseLine = 'middle';
            ctx.fillText(legend, centerX, centerY);
        }
    }

    return (
        <div className="chart-wrapper container mt-2 doughnut-chart-div">
            <div className="flex-container-charts">
                <LoadingScreen isActive={consentMonitoringLoading} />

                { chartData.map((chart) =>{
                        return (
                            <div className="flex-item-chart" key={chart.title}>
                                <Doughnut data={chart} options={options} plugins={[doughnutLabel]} />
                                <div style={{ height: 5 }}></div>
                                <strong>{chart.title}</strong>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default DoughnutChart;
