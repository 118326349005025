import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Select, Space } from 'antd';
import LoadingScreen from '../../components/elements/LoadingScreen';
import { consentChartOptions } from './chartOptions';

// Custom plugin to add margin between legend and chart
export const customMarginPlugin = {
    id: 'customMarginPlugin',
    beforeInit: function (chart) {
        const originalFit = chart.legend.fit;
        chart.legend.fit = function fit() {
            originalFit.bind(chart.legend)();
            this.height += chartsLegendMargin; // Adds the extra margin
        };
    },
};
export const chartsLegendMargin = 25;

const LineChart = (props) => {
    const {
        data,
        width,
        height,
    } = props;

    const filterDataByConsentName = (consentName) => {
        const filteredData = data?.filter(item => item.consent_name === consentName);
        return {
            labels: filteredData?.map(item => item.timestamp),
            datasets: [
                {
                    label: 'Granted',
                    data: filteredData?.map(item => item.granted),
                    fill: false,
                    backgroundColor: 'rgb(43, 43, 181)',
                    borderColor: 'rgb(43, 43, 181)',
                    borderWidth: 3, // Adjust line thickness
                    tension: 0.4, // Adjust curve tension (0.4 for moderate curve)
                    pointRadius: 0, // Remove data points
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                },
                {
                    label: 'Denied',
                    data: filteredData?.map(item => item.denied),
                    fill: false,
                    backgroundColor: 'rgb(255, 189, 136)',
                    borderColor: 'rgb(255, 189, 136)',
                    borderWidth: 3, // Adjust line thickness
                    tension: 0.4, // Adjust curve tension (0.4 for moderate curve)
                    pointRadius: 0, // Remove data points
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                },
            ],
        };
    };


    const consentNames = Array.from(new Set(data?.map(item => item.consent_name)));
    const adStorage = consentNames?.filter((name) => name == "ad_storage")[0];

    const [selectedConsent, setSelectedConsent] = useState(adStorage);
    const [chartData, setChartData] = useState(filterDataByConsentName(adStorage));


    useEffect(() => {
        setChartData(filterDataByConsentName(selectedConsent));
    }, [selectedConsent, data]);


    useEffect(() => {
        setSelectedConsent(adStorage);
    }, [data]);


    const consentOptions = consentNames?.map((name) => (
        {
            value: name,
            label: name
        }
    ));

    return (
        <div style={{
            position: "relative",
        }}>
            <Select
                popupMatchSelectWidth={190}
                placeholder="Select a consent parameter"
                optionFilterProp="label"
                onChange={(e) => setSelectedConsent(e)}
                options={consentOptions}
                value={selectedConsent}
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    minWidth: 190,
                }}
            />
            <Line
                data={chartData}
                plugins={[customMarginPlugin]}
                options={consentChartOptions(2000)}
                width={width}
                height={height}
            />
        </div>
    );
};

export default LineChart;
