import FetchService from './fetch.service';
import localStorageService from './localStorage.service';

const fetchService = new FetchService();

class ProfileService {
    getCompanyProfileInfo = async (params = {}) => {
        let {
            companyId,
        } = params;

        if (!companyId) companyId = localStorageService.getCompanyId();

        const response = await fetchService(`/profiles/company_details/${companyId}`, {
            method: 'GET',
            cache: 'no-cache',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        return response;
    }

    saveProfileData = async (params = {}) => {
        const { curModalSaveFunc } = params;

        switch (curModalSaveFunc) {
            case "profile_details":
                return await this.saveUserProfileInfo(params);
            case "company_details":
                return await this.saveCompanyInfo(params);
            case "billing_details":
                return await this.saveCompanyBillingInfo(params);
            default:
                return;
        }
    }

    saveUserProfileInfo = async (params = {}) => {
        let {
            firstName,
            lastName,
            fullName,
            email,
            phoneNum,
        } = params;

        const userData = localStorageService.getUserData();

        const reqData = {
            user_id: userData?.user_id,
            first_name: firstName,
            last_name: lastName,
            full_name: fullName,
            email,
            phone_number: phoneNum,
        };

        const response = await fetchService(`/profiles/save_user_profile_data/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(reqData),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        userData.full_name = fullName;
        userData.email = email;
        userData.phone_number = phoneNum;

        localStorageService.setUserData(userData);

        return response;
    }

    saveCompanyInfo = async (params = {}) => {
        let {
            companyId,
            companyName,
            chamberOfCommerceNumber,
            vatNumber,
            billingStreet1,
            billingStreet2,
            billingPostcode,
            billingProvince,
            billingCity,
            billingCountry,
        } = params;

        const reqData = {
            company_id: companyId,
            company_name: companyName,
            cocn: chamberOfCommerceNumber,
            vat_number: vatNumber,
            street1: billingStreet1,
            street2: billingStreet2,
            postcode: billingPostcode,
            province: billingProvince,
            city: billingCity,
            country: billingCountry,
        };

        const response = await fetchService(`/profiles/save_company_profile_data/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(reqData),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        const companyInfo = localStorageService.getCompanyInfo();
        companyInfo.name = companyName;

        localStorageService.setCompanyInfo(companyInfo);

        return response;
    }
    
    saveCompanyBillingInfo = async (params = {}) => {
        let {
            companyId,
            billingFullName,
            billingEmail,
            invoiceRefNumber,
        } = params;

        const reqData = {
            company_id: companyId,
            fullname: billingFullName,
            email: billingEmail,
            invoice_ref_number: invoiceRefNumber,
        };

        const response = await fetchService(`/profiles/save_billing_profile_data/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(reqData),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        return response;
    }
}

export default new ProfileService();
