import React, { useState, useEffect } from 'react';
import { Button } from "antd";

export const ActionButton = (props) => {
    const {
        text = "Save",
        type = "",
        disabled = false,
        onClickFunction = () => {},
        loading = false,
        icon = null,
        width,
        danger = false,
    } = props;

    const [localLoading, setLocalLoading] = useState(loading);
    useEffect(() => setLocalLoading(loading), [loading]);

    return (
        <Button
            type={type}
            disabled={disabled}
            onClick={onClickFunction}
            loading={localLoading}
            icon={icon}
            style={width ? { width: width } : ""}
            danger={danger}
        >
            {text}
        </Button>
    );
};
