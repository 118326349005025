import { React, useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FiletypeDoc, Slack, InfoCircleFill } from 'react-bootstrap-icons';
import { Input } from 'antd';

export const SlackNotifModal = ({ context }) => {
    const {
        notifImplementationLink,
        enableSlackNotif,
        slackChannel,
        slackWorkspaceId,
        slackWebhook,
        setEnableSlackNotif,
        setSlackChannel,
        setSlackWorkspaceId,
        setConfigParams,
    } = useContext(context);

    const [notifStatus, setNotifStatus] = useState(enableSlackNotif);
    const [channel, setChannel] = useState(slackChannel);
    const [workspaceId, setWorkspaceId] = useState(slackWorkspaceId);
    const [configParamsToSave, setConfigParamsToSave] = useState({});

    const [ showGuideDiv, setShowGuideDiv ] = useState(false);


    useEffect(() => {
        setNotifStatus(enableSlackNotif);
        setChannel(slackChannel);
        setWorkspaceId(slackWorkspaceId);
    }, [enableSlackNotif, slackChannel, slackWorkspaceId]);

    const handleNotifStatusChange = () => {
        setNotifStatus(!notifStatus);
        setEnableSlackNotif(!notifStatus);
    }
    const handleChannelChange = (event) => {
        setChannel(event.target.value);
        setSlackChannel(event.target.value);
    }
    const handleWorkspaceIdChange = (event) => {
        setWorkspaceId(event.target.value);
        setSlackWorkspaceId(event.target.value);
    }

    useEffect(() => {
        const newObject = { ...configParamsToSave };
        newObject.slack_updates = notifStatus;
        newObject.slack_channel = channel;
        newObject.slack_workspace_id = workspaceId;
        setConfigParamsToSave(newObject);
    }, [notifStatus, channel, workspaceId]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return(
        <div className="modal-elems-rows">
            <div>
                <h5 className='small-heading'>CONFIGURATION</h5>
                <h2>Slack notifications</h2>
            </div>
            <div className="container-guide modal-panel-button-row">
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={notifImplementationLink}>
                        <div className="file-icon" style={{ color: '$cc-blue' }}>
                            <FiletypeDoc size={30} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up Slack notifications</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" checked={notifStatus} onChange={handleNotifStatusChange}  />
                    <label className="form-check-label" htmlFor="slack_notif_enabled">Enable Slack notifications</label>
                </div>
            </div>
            <div style={{ position: 'relative' }}>
                {
                    showGuideDiv && 
                    <div className='hoverable-div-parent' style={{ left: '10em' }}>
                        <p className='hover-content'>
                            Check out our easy-to-follow guides at the top of 
                            the page to ensure successful configuration and setup of Slack notifications
                        </p>
                    </div>
                }
                <h3 className="modal-category mb-0">
                    Account details
                    <InfoCircleFill 
                        size={15} 
                        className='info-icon' 
                        onMouseEnter={() => setShowGuideDiv(true)}
                        onMouseLeave={() => setShowGuideDiv(false)}
                    />
                </h3>
                <p>
                    Add the workspace ID of your Slack account below.
                </p>
                <Input className='wide-input' type="text" placeholder="codecube.slack.com" value={workspaceId} onChange={handleWorkspaceIdChange}/>
            </div>
            <div>
                <p className='mb-2'>
                    Add the name of the Slack channel where you would 
                    like to receive your notifications.
                </p>
                <Input className='wide-input' type="text" placeholder="code-cube-alerts" value={channel} onChange={handleChannelChange} />
            </div>
            <div className="container-rows modal-panel-button-row">
                <div className='mt-3'>
                    <Link className="panel-button-container" target="_blank" to={slackWebhook.replace("$slack_workspace_id$", workspaceId)}>
                        <div className="panel-button-icon">
                            <Slack size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Connect your workspace</div>
                            <div className="panel-button-desc">Allow our slack bot to connect with your workspace to send you updates</div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
