import localStorageService from "./localStorage.service";

let baseUrl = "";
let accessControlOrigin = "";

switch (process.env.NODE_ENV) {
    case "development":
        baseUrl = "http://127.0.0.1:8000";
        accessControlOrigin = "http://127.0.0.1:8000";
        break;
    default:
        baseUrl = "https://cc-portal-backend-service-6k3dd6vtka-ez.a.run.app";
        accessControlOrigin = "https://cc-portal-2f338.web.app/";
}

class FetchService {
    constructor() {
        return (url, ...params) => {
            const token = localStorageService.getToken();
            const updatedParams = [ ...params ];

            updatedParams[0].headers["Access-Control-Allow-Origin"] = accessControlOrigin;

            if (token) updatedParams[0].headers["Authorization"] = `Bearer ${token}`;

            const fetchUrl = url.startsWith('/') ? baseUrl + url : url;

            return fetch(fetchUrl, ...updatedParams);
        }
    }

    getInstance() {
        return this;
    }
}

export default FetchService;
